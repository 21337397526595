<template>
  <v-app>
    <v-navigation-drawer
        :value="isLoggedIn"
        clipped
        app
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img :src="user && user.avatarUrll">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user && `${user.firstName} ${user.lastName}`}}</v-list-item-title>
            <v-list-item-subtitle>{{ user && user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list>
        <template v-if="isLoggedIn && user.role ==='user'">
          <v-list-item v-if="user.hasTeamLicences" :to="`/admin/employees/${user.id}`">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Mein Team</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.hasTeamLicences" :to="`/admin/licenceAssignments/${user.id}`">
            <v-list-item-icon>
              <v-icon>mdi-certificate</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Meine Lizenzen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.isCourseManager" :to="`/admin/courses`">
            <v-list-item-icon>
              <v-icon>mdi-school</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Kurse managen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/admin/settings">
            <v-list-item-icon>
              <v-icon>mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Einstellungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </template>
        <template v-if="isLoggedIn && user.role !=='user'">
          <template v-for="item in userMenuEntries">

            <v-list-item v-if="!item.items" :key="item.link" :to="item.link">
              <v-list-item-icon>
                <v-icon v-text="item.icon"/>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
              </v-list-item-content>
            </v-list-item>
            <v-list-group
                v-else
                :key="item.title"
                v-model="item.active"
                :prepend-icon="item.icon"
                no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                  v-for="child in item.items"
                  :key="child.title"
                  :to="child.link"
              >
                <v-list-item-icon>
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </template>
        <template v-if="managedUsers.length > 0">
          <v-divider/>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">Von mir verwaltet:</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
              v-for="user in managedUsers"
              :key="user.id"
              sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{getTitle(user)}}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="({title, icon, link}, i) in management"
                :key="i"
                link
                :to="`${link}/${user.id}`"
            >
              <v-list-item-title v-text="title"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <v-divider/>
        <v-list-item @click="doLogout">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
        clipped-left
        app
        color="primary"
        dark
        dense
    >
      <div class="d-flex align-center">
        Stuck-Admin
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-progress-linear indeterminate :active="true" :color="progressActive ? 'red' : 'white'"></v-progress-linear>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import store from "@/store";

export default {
  name: "AdminLayout",
  data () {
    return {
      instances: null,
      progressActive: false,
      items: [
        {
          title: 'Benutzer',
          icon: 'mdi-account',
          link: '/admin/users',
          auth: ['admin', 'sale'],
        },
        {
          title: 'Kurs-Gruppen',
          icon: 'mdi-shopping',
          link: '/admin/products',
          auth: ['admin','sale'],
        },
        {
          title: 'Lizenzen',
          icon: 'mdi-certificate',
          link: '/admin/licences',
          auth: ['admin', 'sale'],
        },
        {
          title: 'Kurse',
          icon: 'mdi-account-group',
          link: '/admin/courses',
          auth: ['admin','author'],
        },
        {
          title: 'Kapitel',
          icon: 'mdi-account-group',
          link: '/admin/chapters',
          auth: ['admin','author'],
        },
        {
          title: 'Lektionen',
          icon: 'mdi-account-group',
          link: '/admin/lessons',
          auth: ['admin','author'],
        },
      ],
      management: [
        {title: 'Team', icon: 'mdi-account-group', link: '/admin/employees'},
        {title: 'Lizenzen', icon: 'mdi-certificate', link: '/admin/licenceAssignments'},
        {title: 'Statistik', icon: 'mdi-chart-line', link: '/admin/statistics'},
      ],
    }
  },
  methods: {
    doLogout() {
      this.$store.dispatch('logout').then(() =>{
        this.$router.push({name: 'adminLogin'})
      })
    },
    getTitle(user) {
      return user ?  user.companyName ? user.companyName : `${user.firstName} ${user.lastName}` : ''
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapState(['user', 'managedUsers']),
    userMenuEntries() {
      return this.items.filter(entry => entry.auth && entry.auth.includes(this.user?.role))
    }
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,

}
function beforeRouteEnterOrUpdate(to, from, next) {
  const user = store.state.user
  console.log(to.path)
  if(!user && to.path !=="/admin/login") {
    next('/admin/login')
  } else if(to.path === "/admin") {
    const routeName = store.getters.hasRole('admin') ? 'users' :
        store.getters.hasRole('author') ? 'courses' :
            store.getters.hasRole('sale') ? 'products' :
                store.getters.hasRole('user') ? 'employees' : ''
    next({ name: routeName, params: {userId: user.id }})
  }
  else if(user && to.path ==="/admin/login") {
    next( '/admin')
  }
  next()
}
</script>

<style scoped>

</style>
