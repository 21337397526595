<template>
  <v-app>

    <div>
      Benutzerkurse
      <div :key="course.id" v-for="course in courses">
        <div class="text-h5 text-decoration-underline">{{course.title}}</div>
        <p>Tags: {{course.tags.map(tag => tag.name).join(', ') }}</p>
        <img :src="imageUrl(course)">
        <div class="ml-2" :key="chapter.id" v-for="chapter in course.chapters">
          <div class="text-h6">{{chapter.chapter.title}}({{chapter.aliasTitle}} / {{chapter.startDate}})</div>
          <a v-if="chapter.chapter.content" :href="downloadUrl(chapter.chapter.content)">Kapitelinhalt</a>
          <div class="ml-4" :key="lesson.id" v-for="lesson in chapter.chapter.lessons">
            <div class="text-h6">{{lesson.lesson.title}}({{lesson.aliasTitle}})</div>
            <a v-if="lesson.lesson.content" :href="downloadUrl(lesson.lesson.content)">Lektionsinhalt</a>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>å

<script>
import APIService from "@/services/APIService";

export default {
  name: "UserCourses",
  data() {
    return {
      courses: []
    }
  },
  methods: {
    downloadUrl(content) {
      console.log("xxx", content)
      return content.length > 0 ? `${process.env.VUE_APP_ROOT_API}/content/${content[0].path}` : ''
    },
    imageUrl(course) {
      return course.image ? `${process.env.VUE_APP_ROOT_API}/content/${course.image.path}` : ''
    }
  },

  async beforeRouteEnter(to, from, next) {
    const courses = await APIService.getUserCourses(to.params.userId)
    next(vm => vm.courses = courses)
  }
}
</script>

<style scoped>
  img {
    max-width: 200px;
  }
</style>
