import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import APIService from "@/services/APIService";

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
  },
  state: {
    user: null,
    users: [],
    managedUsers: [],
    options: {
      itemsPerPage: 5
    }
  },
  getters: {
    isLoggedIn: (state) => {
      return state.user !== null
    },
    hasRole: (state) => (role) => {
      return state?.user?.role === role
    },
    accessToken: (state) => {
      return state?.user?.tokens?.access?.token
    },
    refreshToken: (state) => {
      return state?.user?.tokens?.refresh?.token
    }
  },
  mutations: {
    updateUser(state, data) {
      const {user, tokens} = data
      state.user = user
      state.user.tokens = tokens
    },
    updateTokens(state, tokens) {
      state.user = { ...state.user, tokens }
    },
    async logout(state) {
      state.user = null
    },
    updateUsers(state,users) {
      this.state.users = users
    },
    updateManagers(state,managers) {
      state.user.managers = managers
    },
    updateManagedUsers(state,managedUsers) {
      state.managedUsers = managedUsers
    },
    updateItemsPerPage(state, itemsPerPage) {
      state.options = { ...state.options, itemsPerPage }
    }
  },
  actions: {

    login({commit}, {email, password}){
      console.log("Login", email, password)
      return APIService.login(email, password).then((user) => {
        console.log("Response", user)
        commit('updateUser', user)
      })
    },
    async logout({commit, getters}){
      if(getters.isLoggedIn) {
        await APIService.logout(store.getters.refreshToken)
        commit('logout')
      }
    },
    refreshToken({commit,state}) {
      const token = state?.user?.tokens?.refresh?.token ?? 'invalid'
      return APIService.refreshToken(token).then(tokens => {
        commit('updateTokens', tokens)
        return tokens
      })
    },
    fetchUsers({commit}) {
      return APIService.getUsers().then((users) => {
        console.log("users", users)
        commit('updateUsers', users)
      })
    },
    fetchManagedUsers({commit, state}) {
      return APIService.getManagedUsers(state.user.id).then((users) => {
        console.log("users", users)
        commit('updateManagedUsers', users)
      })
    },
  }
})

export default store
